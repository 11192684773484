@import "scss/index";
.input {

  & > div:first-child {
    background-color: rgba($color-platoon, 0.08);

    label {
      color: rgba($color-platoon, 0.48) !important;
    }
  }

  input {
    caret-color: $color-platoon;
    color: $color-prestige !important;
  }

  @media (hover: hover) {
    &:hover {
      & > div:first-child {
        background-color: figmaLighten(rgba($color-platoon, 0.08), 3%);
      }
    }
  }

  &.focused {
    & > div:first-child {
      background-color: figmaDarken(rgba($color-platoon, 0.08), 3%);
    }
  }

  &.filled:not(.errored):not(.focused) {

    & > div:first-child {
      background-color: figmaDarken(rgba($color-platoon, 0.08), 3%);
    }
  }
}
