@import "scss/index";
@mixin button-style-color($color, $opacity) {
  background-color: $color;

  &:not(.disabled) {
    @include button-colors($color, background-color);
  }

  &.disabled {
    opacity: $opacity;
  }
}

.button {
  height: 40px;
}

.platoon {
  @include button-style-color($color-platoon, 0.4);
}

.jaws {
  @include button-style-color($color-jaws, 0.4);
}
