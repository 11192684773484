@import "scss/index";
.modal {
  background-size: 1024px auto;
  background-position: top center;
}

@include media-mobile {

  .upload {
    width: 131px;
  }
}

@include media-tablet {

  .upload {
    width: 163px;
  }
}

@include media-desktop {

  .upload {
    width: 131px;
  }
}
