@import "scss/index";
.calcWidth {
  width: calc(100% - 25.3%);
}

body {

  &:global(.w-full) {

    .calcWidth {
      width: 100%;
    }
  }
}
