@import "scss/index";
.container {
  max-width: 100%;

  :global(.ReactCrop__drag-handle) {
    &::after {
      border-color: $color-platoon;
      background-color: $color-platoon;
    }
  }

  :global(.ReactCrop__drag-bar) {
    background-color: $color-platoon;
  }

  :global(.ReactCrop__image) {
    max-width: 100%;
    border-radius: 16px;
  }

  :global(.ReactCrop__crop-selection) {
    opacity: 0;
    transition: opacity ease 0.25s;
  }

  &.loaded {

    :global(.ReactCrop__crop-selection) {
      opacity: 1;
    }
  }
}
