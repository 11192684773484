@import "scss/index";
.container {
  display: block !important;
}

.circle {
  background-color: rgba($color-platoon, 0.08);
  padding-top: 100%;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}